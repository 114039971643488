import styles from "./Confirmation.module.scss";
import groot from "../../assets/picsvg_download.svg";
import { useEffect } from "react";
import { menuContext } from "../Utils/Context/menuContext";
import { useContext } from "react";
import { useRef } from "react";
function ConfirmSending() {
   const myRef = useRef(null);
   const { setShowConfirmation } = useContext(menuContext);
   const audio = new Audio("/sound.mp3");

   function closeConfirmation() {
      const timer = setTimeout(() => {
         setShowConfirmation(false);
      }, 2000);
      return () => {
         clearTimeout(timer);
      };
   }

   function PlaySound() {
      audio.play();
   }
   useEffect(() => {
      PlaySound();
      closeConfirmation();
   }, []);

   return (
      <div className={styles.confirmModale} ref={myRef}>
         <div className={styles.container}>
            <img src={groot} alt="groot confirm" />
         </div>
      </div>
   );
}

export default ConfirmSending;

import styles from "../Home/Home.module.scss";
import LinkedIn from "../../assets/linkedIn.svg";
import Github from "../../assets/github.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { menuContext } from "../Utils/Context/menuContext";
import Canvas from "./Canvas/Canvas";
function Home() {
   const { inHome, setInHome, forMobile } = useContext(menuContext);
   const myRef = useRef(null);
   useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
         const [entry] = entries;
         entry.isIntersecting ? setInHome(true) : setInHome(false);
      });
      observer.observe(myRef.current);
   }, [inHome, setInHome]);

   return (
      <section id="home" className={styles.home} ref={myRef}>
         {!forMobile && (
            <div id={styles.homePanel}>
               <Canvas />
               <a href="#contact">Me Contacter</a>
            </div>
         )}
         <a id={styles.aboutPanel} className={styles.hoverFSize} href="#about">
            <h2>À Propos</h2>
         </a>
         <a
            id={styles.projectsPanel}
            className={styles.hoverFSize}
            href="#projects"
         >
            <h2>Projets</h2>
         </a>
         <a
            id={styles.skillsPanel}
            className={styles.hoverFSize}
            href="#skills"
         >
            <h2>Compétences</h2>
         </a>
         <a id={styles.contactPanel} href="#contact">
            <h2>Contact</h2>
         </a>
         <a id={styles.github} href="https://github.com/Dullahan83">
            <img src={Github} alt="github logo" />
         </a>

         <a
            id={styles.linkedIn}
            href="https://www.linkedin.com/in/renaud-caira"
            target="_blank"
            rel="noreferrer noopener"
         >
            <img src={LinkedIn} alt="linkedIn logo" />
         </a>
      </section>
   );
}

export default Home;

import { useContext, useEffect, useState } from "react";
import { menuContext } from "../Utils/Context/menuContext";
import styles from "./Menu.module.scss";
import { motion, AnimatePresence } from "framer-motion";

function Menu() {
   const { inHome, forMobile } = useContext(menuContext);
   const animationVariant = {
      desktopIn: {
         y: "-50%",
         x: -130,
         opacity: 0.8,
         duration: 0.5,
         stiff: 5,
      },
      desktopOut: {
         y: "-50%",
         x: -180,
         opacity: 0,
      },
      mobileIn: {
         x: "calc(50vw - 50%)",
         y: -5,
         opacity: 0.8,
         duration: 0.5,
         stiff: 5,
      },
      mobileOut: { x: "calc(50vw - 50%)", y: -50, opacity: 0 },
   };
   return (
      <AnimatePresence>
         {!inHome && (
            <motion.div
               key="menu"
               initial={forMobile ? "mobileOut" : "desktopOut"}
               animate={!inHome ? (forMobile ? "mobileIn" : "desktopIn") : null}
               exit={forMobile ? "mobileOut" : "desktopOut"}
               className={`${styles.menu} `}
               variants={animationVariant}
            >
               <a href="#home">
                  <p>Home</p>
                  <img src="/images/icons/home.svg" alt="" />
               </a>
               <a href="#about">
                  <p>À Propos</p>
                  <img src="/images/icons/about.svg" alt="about section icon" />
               </a>
               <a href="#projects">
                  <p>Projets</p>
                  <img
                     src="/images/icons/projects.svg"
                     alt="projects section icon"
                  />
               </a>
               <a href="#skills">
                  <p>Stack</p>
                  <img src="/images/icons/stack.svg" alt="stack section icon" />
               </a>
               <a href="#contact">
                  <p>Contact</p>
                  <img
                     src="/images/icons/contact.svg"
                     alt="contact section icon"
                  />
               </a>
            </motion.div>
         )}
      </AnimatePresence>
   );
}

export default Menu;

import React, { Component } from "react";
import styles from "./Canvas.module.scss";

class Canvas extends Component {
   constructor() {
      super();
      this.canvasRef = React.createRef(null);

      //   this.offscreenCanvas = null;
      //   this.worker = new Worker("./worker.js");
   }

   createCanvas() {
      const canvas = this.canvasRef.current;
      const ctx = canvas.getContext("2d");
      let adjustX = 0;
      let adjustY = 0;

      let width = canvas.getBoundingClientRect().width;
      let height = canvas.getBoundingClientRect().height;
      canvas.width = width;
      canvas.height = height;

      let particleArray = [];
      const mouse = {
         x: null,
         y: null,
         radius: 30,
      };

      canvas.addEventListener("mousemove", (e) => {
         mouse.x = e.x;
         mouse.y = e.y + window.scrollY;
      });

      function drawText() {
         let line1 = "Renaud Caira";
         let line2 = "Developpeur Web";
         let line3 = "Bienvenue sur mon portfolio";
         let size = window.innerWidth < 1300 ? width / 18 : width / 22;
         let size2 = size / 1.66;
         ctx.strokeStyle = "#1f2937";

         ctx.save();
         ctx.fillStyle = "#1f2937";
         ctx.font = size + "px Verdana";
         ctx.fillText(
            line1,
            canvas.width / 2 - ((size / 1.75) * line1.length) / 2,
            canvas.height / 2 - size * 2.5
         );
         ctx.fillText(
            line2,
            canvas.width / 2 - ((size / 1.65) * line2.length) / 2,
            canvas.height / 2 - size * 1.5
         );
         ctx.restore();
         ctx.font = size2 + "px Verdana";
         ctx.fillStyle = "#1f2937";
         ctx.fillText(
            line3,
            canvas.width / 2 - (((size2 + 2.5) / 2) * line3.length) / 2,
            canvas.height / 2 - 15
         );
      }
      drawText();
      let textData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      class Particle {
         constructor(x, y) {
            this.x = x;
            this.y = y;
            this.size = 1;
            this.baseX = this.x;
            this.baseY = this.y;
            this.density = Math.floor(Math.random() * 30);
         }

         draw() {
            ctx.fillStyle = "#1f2937";
            ctx.beginPath();
            ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
         }

         update() {
            let dx = mouse.x - this.x;
            let dy = mouse.y - this.y;
            let distance = Math.sqrt(dx * dx + dy * dy);

            let forceDirectionX = dx / distance;
            let forceDirectionY = dy / distance;
            let maxDistance = mouse.radius;
            let force = ((maxDistance - distance) / maxDistance) * 3;
            let directionX = forceDirectionX * force * this.density;
            let directionY = forceDirectionY * force * this.density;
            if (distance < mouse.radius) {
               this.x -= directionX;
               this.y -= directionY;
            } else {
               if (this.x !== this.baseX) {
                  dx = this.x - this.baseX;
                  this.x -= dx / 5;
               }
               if (this.y !== this.baseY) {
                  dy = this.y - this.baseY;
                  this.y -= dy / 5;
               }
            }
         }
      }

      function init() {
         particleArray = [];
         for (let y = 0, y2 = textData.height; y < y2; y++) {
            for (let x = 0, x2 = textData.width; x < x2; x++) {
               let index = y * 4 * textData.width + x * 4 + 3;
               if (textData.data[index] > 128) {
                  let positionX = x + adjustX;
                  let positionY = y + adjustY;
                  particleArray.push(new Particle(positionX, positionY));
               }
            }
         }
      }
      init();

      function animate() {
         ctx.clearRect(0, 0, canvas.width, canvas.height);
         for (let i = 0; i < particleArray.length; i++) {
            particleArray[i].draw();
            particleArray[i].update();
         }
         requestAnimationFrame(animate);
      }
      animate();
      window.addEventListener("resize", () => {
         width = canvas.clientWidth;
         height = canvas.clientHeight;
         canvas.width = width;
         canvas.height = height;

         let timer = setTimeout(() => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            drawText();
            textData = ctx.getImageData(0, 0, width, height);
            init();
         }, 150);
         return () => {
            clearTimeout(timer);
         };
      });
   }

   debounce(func) {
      var timer;
      return function (e) {
         if (timer) {
            clearTimeout(timer);
         }
         timer = setTimeout(func, 100);
      };
   }

   componentDidMount() {
      this.createCanvas(this.canvasRef.current);
   }

   render = () => {
      return (
         <canvas
            id="canvas1"
            className={styles.canvas1}
            ref={this.canvasRef}
         ></canvas>
      );
   };
}
export default Canvas;

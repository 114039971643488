import "./App.scss";
import React, { Suspense } from "react";
import Home from "./component/Home/Home";
import Separator from "./component/Separator/Separator";
import ConfirmSending from "./component/ConfirmationSend/Confirmation";
import Menu from "./component/Menu/Menu";
import { useState } from "react";
import { menuContext } from "./component/Utils/Context/menuContext";
import { useEffect } from "react";

const About = React.lazy(() => import("./component/About/About"));
const Projects = React.lazy(() => import("./component/Projects/Projects"));
const Skills = React.lazy(() => import("./component/Skills/Skills"));
const Contact = React.lazy(() => import("./component/Contact/Contact"));
const Footer = React.lazy(() => import("./component/Footer/Footer"));

function App() {
   const [forMobile, setForMobile] = useState(
      window.innerWidth > 992 ? false : true
   );
   const [isResized, setIsResized] = useState(false);
   const [inHome, setInHome] = useState();
   const [showConfirmation, setShowConfirmation] = useState(false);
   // const dbHost = "http://localhost:3000/";
   const dbHost = "https://portfolioapi.renaud-caira.com/";
   const imgFolderPath = `${dbHost}images/`;

   // useEffect(() => {
   //    if (document.readyState !== "complete") {
   //       document.addEventListener("load", () => {
   //          window.innerWidth < 992 ? setForMobile(true) : setForMobile(false);
   //       });
   //    }
   // }, []);

   useEffect(() => {
      window.addEventListener("resize", () => {
         window.innerWidth < 992 ? setForMobile(true) : setForMobile(false);
         setIsResized(true);
      });
      setIsResized(false);
   }, [isResized]);

   return (
      <div id="wrapper">
         <menuContext.Provider
            value={{
               inHome,
               setInHome,
               dbHost,
               imgFolderPath,
               forMobile,
               showConfirmation,
               setShowConfirmation,
            }}
         >
            <>
               <Home />
               <Separator bg="bg3" sep="separator2" />
               <Suspense>
                  <About />
                  <Menu />
                  <Projects />
                  <Skills />
                  <Contact />
                  <Footer />
                  {showConfirmation && <ConfirmSending />}
               </Suspense>
            </>
         </menuContext.Provider>
      </div>
   );
}

export default App;
